import styled from 'styled-components';

export const Container = styled.div`
  ${({ styles }) => {
    switch (styles) {
      case 'pdp2023':
        return `
      & {
        position:relative;
      }

      &:before {
        content: " ";
        display: block;
        width: calc(100% + 16px);
        height: 80px;
        margin-top: -8px;
        margin-left: -8px;
        background: transparent;
        border-radius: 80px;
        border: 8px solid transparent;
        position: absolute;
        transition: border 1s;
      }

      &.pressed:before {
        border: 8px solid #F5CCDD;
      }

      & > button.processing {
        border-radius: 80px!important;
        min-height: 64px;
        z-index: 1;
        top: 0;
      }
      `;
      case 'default':
      default:
        return ``;
    }
  }}
`;

export const AddToCartButton = styled.button`
    position: relative;
    display: block;
    text-align: center;
    font-size: 18px;
    color:#FFF;
    width: 100%;
    padding: 18px 0;
    border-radius: 5px;
    text-decoration: none;
    line-height: 18px;
    vertical-align: middle;
    box-shadow: 0 4px 0 #a54e32;
    transition: all .2s;
    border: 0;
    cursor: pointer;
    outline:none;
    background-color: #e26447;
    ${({ overrideColors, backgroundColor }) => {
      const bgColor =
        backgroundColor && backgroundColor !== '' ? backgroundColor : '#e26447';
      return overrideColors ? `background-color: ${bgColor}!important;` : ``;
    }}
    ${({ overrideColors, textColor }) => {
      const txtColor = textColor && textColor !== '' ? textColor : '#FFF';
      return overrideColors ? `color: ${txtColor}!important;` : ``;
    }}
    ${({ overrideColors, backgroundColorHover }) => {
      const bgHover =
        backgroundColorHover && backgroundColorHover !== ''
          ? backgroundColorHover
          : '#a54e32';
      return overrideColors
        ? `
        box-shadow: 0 4px 0 ${bgHover}!important;
        `
        : ``;
    }}

    &:active, &:focus {
        border: 0;
    }

    &:hover {
        ${({ overrideColors, backgroundColorHover }) => {
          const bgHover =
            backgroundColorHover && backgroundColorHover !== ''
              ? backgroundColorHover
              : '#a54e32';
          return overrideColors
            ? `background-color: ${bgHover}!important;`
            : ``;
        }}
        & > span {
            top: 2px;
        }
        &:after {
            visibility: visible;
            right: 15px;
        }
    }
    & span {
        position: relative;
        width: 130px;
        display: block;
        margin: auto;
        text-transform: uppercase;
        &:before {
            content: " ";
            background-image: url('/images/cart.svg');
            width: 25px;
            height: 20px;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: -25px;
        }
    }

    &:after {
        content: "\f054";
        font-family: FontAwesome;
        padding-top: 20px;
        right: 30px;
        top: 0;
        font-weight: 100;
        position: absolute;
        visibility: hidden;
        color: #fff;
        font-size: 18px;
        -webkit-transition: .1s linear;
        transition: .1s linear;
    }

    &.processing {

        ${({ overrideColors, backgroundColorHover }) => {
          const bgHover =
            backgroundColorHover && backgroundColorHover !== ''
              ? backgroundColorHover
              : '#a54e32';
          return overrideColors
            ? `background-color: ${bgHover}!important;`
            : ``;
        }}
        & > span {
            top: 0px;
        }
        &:after {
            visibility: hidden;
        }
        
        &:before {
            content: "";
            height: 22px;
            width: 100%;
            background-image: url("/images/three-dots.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
        }
        & > span {
            display: none;
        }
    }

    ${({ pageDesign }) => {
      if (pageDesign === `design2`) {
        return `
          background-color: #BC2E3E;
          -webkit-box-shadow: 0 4px 0 #961423;
          box-shadow: 0 4px 0 #961423;
          &:hover {
            background-color: #961423;
          }
          & > span {
            font-size: 22px;
            font-weight: bold;
            text-transform: uppercase;
            width: 180px;
          }
          & span {
            &:before {
                background: none;
            }
          }
          &.processing {
            background: #961423;
            &:hover {
                &:after {
                    visibility: hidden;
                }
            }
          }
          &:after {
            content: " ";
            background-image: url('/images/cart.svg');
            width: 25px;
            height: 20px;
            background-repeat: no-repeat;
            position: absolute;
            visibility: visible;
            right: 25px;
            top: 17px;
          }

          &:hover {
            &:after {
                visibility: visible;
                right: 25px;
                top: 19px;
            }
          }

          `;
      }
    }}
}
`;

export const WithChildren = styled.button`
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    width: 100%;

    &.processing {
        padding: 18px 0;
        border-radius: 5px;
        
        ${({ backgroundColorHover }) => {
          return `
                background: ${backgroundColorHover ?? '#a54e32'};
            `;
        }}
        
        & > span {
            top: 0px;
        }
        & > div {
            display: none;
        }
        &:after {
            visibility: hidden;
        }
        
        &:before {
            content: "";
            height: 22px;
            width: 100%;
            background-image: url("/images/three-dots.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
        }
        & > span {
            display: none;
        }
    }
}
`;
