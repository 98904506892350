import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as S from './AddToCartButtonV4.styles';
import { addToCart } from '../../utils/addToCart';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import CrossSellPopup from './CrossSellPopup';
import { addToSubscription } from '../../utils/activeSubscriptions';
import { navigate } from 'gatsby';
import useLocalStorage from '../../hooks/useLocalStorage';
import useButtonEventPressed from '../../hooks/useButtonEventPressed';

const AddToCartButton = props => {
  const {
    buttonText,
    children,
    triggerCheckoutButton,
    overrideColors,
    textColor,
    backgroundColor,
    backgroundColorHover,
    crossSellEndpointApiUrl,
    addPetInformation,
    styles
  } = props;

  const sendToStagingCheckout = process.env.GATSBY_FUNNEL_STAGING;
  const overrideStagingCheckoutUrl =
    process.env.GATSBY_CHECKOUT_API_URL_STAGING;
  const stagingCheckoutUrls = useMemo(
    () =>
      process.env.GATSBY_FUNNEL_STAGING_URLS?.split('|') || [
        process.env.GATSBY_CHECKOUT_API_URL_STAGING
      ],
    []
  );

  const context = useContext(FunnelContext);

  useButtonEventPressed('button-press-trigger');

  const {
    upsell: upsellUrl,
    currency,
    onetime,
    subscription,
    currentCategory,
    setCurrentCategory,
    currentProduct,
    setCurrentProduct,
    path,
    pageDesign,
    bumpOfferChecked,
    setBumpOfferChecked,
    bumpOfferIndex,
    setBumpOfferIndex,
    frequency,
    setFrequency,
    billingFrequency,
    setSubmittedToCart,
    tubIndexChecked,
    extraObjects,
    setExtraObjects
  } = context;

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [crossSellConfirmed, setCrossSellConfirmed] = useState(false);

  const [, setCrossSellResponse] = useLocalStorage('cross-sell-response-data');
  const [extraObjects2] = useLocalStorage('extraObjects', {});
  const [answersLS] = useLocalStorage('answers', {});
  const [addToCartButtonClicked, setAddToCartButtonClicked] = useLocalStorage(
    'add_to_cart_button_clicked',
    false
  );

  const [stagingCheckoutUrl, setStagingCheckoutUrl] = useState(
    overrideStagingCheckoutUrl
  );

  useEffect(() => {
    if (sendToStagingCheckout) {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });
      if (params) {
        setStagingCheckoutUrl(
          params.checkoutUrl || stagingCheckoutUrls[params.cko || 0]
        );
      }
    }
  }, [
    sendToStagingCheckout,
    setStagingCheckoutUrl,
    overrideStagingCheckoutUrl,
    stagingCheckoutUrls
  ]);

  useEffect(() => {
    setButtonDisabled(addToCartButtonClicked);
    setButtonClicked(addToCartButtonClicked);
    if (addToCartButtonClicked) {
      setTimeout(() => {
        setAddToCartButtonClicked(false);
      }, 5000);
    }
  }, [
    setButtonDisabled,
    setButtonClicked,
    addToCartButtonClicked,
    setAddToCartButtonClicked
  ]);

  useEffect(() => {
    const newExtraData = Object.assign(extraObjects, extraObjects2);
    setExtraObjects(newExtraData);
  }, [extraObjects, extraObjects2, setExtraObjects]);

  const triggerAddToCart = () => {
    if (Builder.isEditing) {
      return false;
    }

    if (buttonClicked) {
      return false;
    }

    setButtonDisabled(true);
    setButtonClicked(true);
    setSubmittedToCart(true);
    setAddToCartButtonClicked(true);

    let petInfo = {};
    if (addPetInformation && addPetInformation.length > 0) {
      addPetInformation.forEach(item => {
        let {
          value: itemValue = '',
          checkAnswer: itemCheckAnswer = true,
          checkQueryParams: itemCheckQueryParams = false,
          answerKey,
          queryParamKey,
          convertToNumber = false,
          convertToArray = false
        } = item;
        let petInfoValue = itemValue;

        if (itemCheckAnswer) {
          petInfoValue = answersLS[answerKey] || '';
        }

        if (itemCheckQueryParams) {
          const params = new URLSearchParams(window.location.search);
          petInfoValue = params.get(queryParamKey) || '';
        }

        if (typeof petInfoValue === 'string' && convertToArray) {
          if (petInfoValue.includes(',')) {
            petInfoValue = petInfoValue.split(',').map(val => {
              return convertToNumber ? parseInt(val) : val;
            });
          } else {
            petInfoValue = convertToNumber
              ? parseInt(petInfoValue)
              : petInfoValue;
            petInfoValue = petInfoValue ? [petInfoValue] : [];
          }
        }

        if (typeof petInfoValue === 'number' && convertToArray) {
          petInfoValue = petInfoValue ? [petInfoValue] : [];
        }

        if (
          typeof petInfoValue === 'string' &&
          !convertToArray &&
          convertToNumber
        ) {
          petInfoValue = parseInt(petInfoValue);
        }

        Object.assign(petInfo, {
          [item.key]: petInfoValue
        });
      });
    }

    const extraData = {
      ...extraObjects,
      currency_code: currency.code,
      currency_symbol: currency.symbol,
      trigger_checkout_button: triggerCheckoutButton,
      sendToStagingCheckout: sendToStagingCheckout ?? false,
      overrideStagingCheckoutUrl: stagingCheckoutUrl,
      setButtonDisabled,
      setButtonClicked,
      setSubmittedToCart,
      upsell_url: upsellUrl,
      petInfo
    };

    if (bumpOfferChecked && extraData?.bump_offer_index_override) {
      setBumpOfferIndex(extraData.bump_offer_index_override);
    }

    if (tubIndexChecked && extraData?.tub_index_override) {
      const productPrefix =
        currentCategory === `onetime` ? `onetime` : `subscription`;
      setCurrentProduct(`${productPrefix}_${extraData?.tub_index_override}`);
    }

    let finalCurrentCategory = currentCategory;
    let finalCurrentProduct = currentProduct;
    let finalBumpOfferChecked = bumpOfferChecked;
    let finalBumpOfferIndex = bumpOfferIndex;
    let finalFrequency = frequency;

    // extraObjects overrides
    let extraObjectsOverride = extraObjects?.extra_objects_override ?? false;
    if (extraObjectsOverride) {
      if (extraObjects?.current_category_override) {
        const categoryOverride =
          extraObjects.current_category_override === 'onetime'
            ? 'onetime'
            : 'subscriptions';
        setCurrentCategory(categoryOverride);
        setExtraObjects(
          Object.assign(extraObjects, { currentCategory: categoryOverride })
        );
        finalCurrentCategory = categoryOverride;
      }
      if (extraObjects?.tub_index_override) {
        const productPrefix2 =
          finalCurrentCategory === `onetime` ? `onetime` : `subscription`;
        const productId = `${productPrefix2}_${extraObjects?.tub_index_override}`;
        setCurrentProduct(productId);
        finalCurrentProduct = productId;
      }
      if (extraObjects?.bump_offer_checked_override) {
        finalBumpOfferChecked =
          extraObjects.bump_offer_checked_override === '0' ? false : true;
        setBumpOfferChecked(finalBumpOfferChecked);
      }
      if (extraObjects?.bump_offer_index_override) {
        setBumpOfferIndex(extraObjects.bump_offer_index_override);
        finalBumpOfferIndex = extraObjects.bump_offer_index_override;
      }
      if (extraObjects?.delivery_frequency_override) {
        setFrequency(extraObjects.delivery_frequency_override);
        finalFrequency = extraObjects.delivery_frequency_override;
      }

      if (extraObjects?.multiple_bump_offer_index) {
        const bumpOffers1 =
          finalCurrentCategory === `onetime`
            ? onetime.bumpoffers
            : subscription.bumpoffers;
        const multiple_bump_offer = bumpOffers1.map((bumpOfferData, index) => {
          if (
            extraObjects?.multiple_bump_offer_index.includes(index.toString())
          ) {
            return bumpOfferData;
          }
          return null;
        });
        Object.assign(extraData, { multiple_bump_offer });
        finalBumpOfferChecked = false;
      }
    }

    const productPrefix =
      finalCurrentCategory === `onetime` ? `onetime` : `subscription`;
    const { discounts, products, bumpoffers } =
      finalCurrentCategory === 'subscriptions' ? subscription : onetime;
    const bumpOfferData = finalBumpOfferChecked
      ? bumpoffers[finalBumpOfferIndex]
      : {};
    const product = products.find(
      (_, index) => `${productPrefix}_${index}` === finalCurrentProduct
    );
    product.schedule =
      finalCurrentCategory === `subscriptions` ? finalFrequency : '';
    product.billingFrequency =
      currentCategory === 'subscriptions' ? billingFrequency : null;

    const funnelUrl = window.location.href;
    addToCart(product, discounts, extraData, bumpOfferData, path, funnelUrl);
  };

  const triggerAddToActiveSubscription = () => {
    setButtonDisabled(true);
    setButtonClicked(true);
    setSubmittedToCart(true);

    const productPrefix =
      currentCategory === `onetime` ? `onetime` : `subscription`;
    const { products } =
      currentCategory === 'subscriptions' ? subscription : onetime;
    const { checkoutData } = products.find(
      (_, index) => `${productPrefix}_${index}` === currentProduct
    );

    addToSubscription({
      apiUrl: crossSellEndpointApiUrl,
      extra: extraObjects,
      checkoutData: checkoutData
    }).then(response => {
      setCrossSellResponse({
        ...response?.data,
        checkoutData: checkoutData
      });
      navigate(`${path}/confirmation`, {
        replace: true
      });
      setButtonDisabled(false);
      setButtonClicked(false);
      setSubmittedToCart(false);
    });
  };

  const triggerOnClick = () => {
    if (Builder.isEditing) {
      return false;
    }

    switch (triggerCheckoutButton) {
      case 'update-active-subscription':
        return triggerAddToActiveSubscription();
      case 'cross-sell':
        if (extraObjects?.active_subscription_id) {
          setCrossSellConfirmed(true);
        }
        break;
      default:
      case 'none':
        return triggerAddToCart();
    }
  };

  return (
    <S.Container styles={styles} className="button-press-trigger">
      {children && (
        <S.WithChildren
          type="button"
          onClick={triggerOnClick}
          disabled={buttonDisabled ? 'disabled' : ''}
          className={buttonClicked ? 'processing' : ''}
          textColor={textColor}
          backgroundColor={backgroundColor}
          backgroundColorHover={backgroundColorHover}
        >
          {children}
        </S.WithChildren>
      )}

      {!children && (
        <S.AddToCartButton
          type="button"
          onClick={triggerOnClick}
          disabled={buttonDisabled ? 'disabled' : ''}
          className={`${buttonClicked ? 'processing' : ''} add-to-card-button`}
          pageDesign={pageDesign}
          textColor={textColor}
          backgroundColor={backgroundColor}
          backgroundColorHover={backgroundColorHover}
          overrideColors={overrideColors}
        >
          <span>{buttonText}</span>
        </S.AddToCartButton>
      )}

      {triggerCheckoutButton === `cross-sell` && (
        <CrossSellPopup
          show={crossSellConfirmed}
          setShow={setCrossSellConfirmed}
          endpointUrl={crossSellEndpointApiUrl}
        />
      )}
    </S.Container>
  );
};

export default AddToCartButton;
